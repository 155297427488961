#recovery-page {
    div.recovery-form {
        .option-container {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            & button {
                min-width: fit-content;
                padding: 0.5rem;
            }
        }
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 0 32px;
        margin: 0 auto;   
        .input-control {
            display: flex;
            flex-direction: column;
            gap: 8px;
            input {
                background-color: transparent;
                color: var(--color-white);
                font-size: 1rem;
                letter-spacing: 0.3px;
                padding: 17px 16px;
                border-radius: 8px;
                border: 1px solid var(--color-black-300);
                outline: none;
                &::placeholder {
                    color: var(--color-black-300);
                }
            }
    
            .error-message {
                font-size: 1rem;
                color: var(--color-error-input);
                align-self: flex-end;
                text-align: end;
                letter-spacing: 0.2px;
                line-height: 100%;
            }
    
            &.error {
                input {
                    color: var(--color-error-input);
                    border-color: var(--color-error-input);
                }
            }
        }
    
        .input-control-otp {
            padding: 20px 0 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
    
            .input-container {
                align-self: center;
                display: flex;
                gap: 8px;
                transition: .3s;
            
                input[class*="otp"] {
                    color: var(--color-white);
                    background-color: transparent;
                    font-size: 28px;
                    height: 56px;
                    width: 35px;
                    text-align: center;
                    border-radius: 8px;
                    border: 1px solid var(--color-black-300);
                    outline: none;
                }
    
                &.error {
                    input {
                        border-color: var(--color-error-input);
                    }
                }
            }
    
            .error-message {
                font-size: 1rem;
                color: var(--color-error-input);
                letter-spacing: 0.2px;
                line-height: 100%;
            }
        }
    }
}

@media screen and (min-width: 500px ) {
    #recovery-page {

        div.recovery-form {
            .input-control-otp {
    
                .input-container {
                    align-self: center;
                    display: flex;
                    gap: 16px;    
                }
            }
        }
    }
 }

 @media screen and (min-width: 767px) {
    #recovery-page {
        div.recovery-form {
            .option-container {

                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;

                button {
                    max-width: 144px;
                }

                .forgot-password {
                    align-self: auto;
                }
            }
        }
    }
}