@import "../videoGrid/videoGridUpload.scss";

#upload-main {
    max-width: 500px;
    margin-inline: auto;

    button {
        margin-inline: auto;
    }
}

@media screen and (min-width: 768px) {
    #upload-main {
        max-width: 500px;
        margin-inline: auto;
        padding: 0;
    }
}