#manual-edit-page {
    .edit-video-container {
            width: 100%;
            height: 100%;
            max-width: 1020px;
            margin: 0 auto;
            padding-bottom: 24px;
            overflow: hidden;
    
    
            .video-container-scrollable {
                display: flex;
                gap: 24px 6px;
                flex-wrap: wrap;
                height: -moz-fit-content;
                width: 100%;
                height: 60vh;
                max-height: 100%;
                overflow: scroll;
                margin-top: 50px;
            }

            .dropzone {
                max-width: 678px;
                margin-inline: auto;
                flex: 1;
                height: 100% !important;
                border: 1px dashed rgba(0, 0, 0, 0.1);
                border-radius: 1rem;
                min-height: 100% !important;
                margin-bottom: auto;
              }
              
            .video {
                position: relative;
                color: var(--color-white);
                width: 100%;
                
                border-radius: 8px;
                padding: 3px;

                video {
                    width: 100%;
                    height: auto;
                    max-height: 160px;
                    border-radius: 8px;
                    border: 2px solid var(--color-white);
                }
            }
    }
}


@media screen and (min-width: 768px) {
    #manual-edit-page {
        .edit-video-container {
            .video {
                min-width: calc(100% / 4 - 13.5px);
                max-width: calc(100% / 4 - 13.5px);
            }
        }
    }
}
    
@media screen and (min-width: 1020px) {
    #manual-edit-page {
        .edit-video-container {
            
            .dropzone {
                max-width: 1000px;
            
                .video {
                    min-width: calc(100% / 4 - 7px);
                    max-width: calc(100% / 4 - 7px);
                }
            }
        }
    }
}