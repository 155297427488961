.choose-action {
    animation: showModal .4s linear;
    width: min(342px, 342px);
    height: min(277px, 277px);
    color: var(--color-white);
    background-color: var(--color-black-400);
    border-radius: 8px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    .modal-title {
        font-size: 19px;
        font-weight: bold;
        letter-spacing: .19px;
        text-transform: uppercase;
        margin-inline: auto;
        margin-bottom: 8px;
    }

    button {
        font-size: 16px;
        height: min(48px, 48px);
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        transition: .3s linear;
        border: none;
        border-radius: 8px;

        &.auto-edit {
            color: var(--color-black-500);
            background-color: var(--color-white);
        }

        &.manual-edit {
            color: var(--color-white);
            letter-spacing: .3px;
            background-color: var(--color-black-300);
        }


        &:active {
            transform: scale(.9);
        }
    }

    .download-selected-scene {
        font-size: 16px;
        line-height: 100%;
        letter-spacing: .3px;
        color: white;
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
    }

    .close-modal {
        color: white;
        position: absolute;
        right: 0;
        top: -2.5rem;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s linear;
        border-radius: 50%;
        border:1px solid currentColor;

        svg {
            height: 10px;
            width: 10px;
        }

        &:active {
            transform: scale(.85);
        }
    }
}

