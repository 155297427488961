@import "./select.scss";
@import "./title.scss";
@import "./mainInfo.scss";
@import "./pleaseNotice.scss";
@import "./onUploadVideo.scss";
@import "./onSendingVideo.scss";
@import "./onError.scss";
@import "./emptyVideoUploadNotice.scss";
@import "./modals/chooseAction.scss";
@import "./modals/fullVideo.scss";
@import "./sectionUpdate.scss";


/* ****  modals */
.layer {
    height: 100vh;
    width: 100vw;
    opacity: 0.8;
    background-color: var(--color-black-600);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    animation: showModal .4s linear;

}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;


    .modal-content {
        position: relative;
    }
}

@keyframes showModal {
    from {opacity: 0;}
    to {opacity: 1}
    
}