@import "../loginForm/loginform.scss";
@import "../loginForm/neverbeenhere.scss";

#login-page {
  .banner {
    background-color: var(--color-turquoise);
    color: var(--color-white);
    text-align: center;
    border-radius: 8px;
    padding: 15px 15px;

  }
  .terms {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    margin: 16px 16px 16px 0;
    border-radius: 8px;
    background-color: var(--color-black-500);
    border: solid 1px;
    border-color: var(--color-black-300);
    accent-color: var(--color-turquoise);
  }
  input[type="checkbox"] {
    /* ...existing styles */
    display: grid;
    place-content: center;
  }
  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--color-turquoise);
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);

  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  label[for=terms]
  {
    line-height: 1.5em;
    color:white;
  }
  a, a:visited, a:hover, a:active {
    color: var(--color-white) !important;
  }
}