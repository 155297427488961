.select-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 24px;

    .select-box-custom {
        position: relative;
        
        .select-box {
            position: relative;
            background-color: var(--color-black-500);
            color: var(--color-white);
            display: flex;
            justify-content: space-between;
            padding: 18px 16px;
            border: 1px solid var(--color-black-300);
            border-radius: 8px;

            &.not-active {

                
                span {
                    color: var(--color-black-300);
                }
            }

            &.active {
            
                
                span {
                    color: var(--color-white);
                }
            }
        }

        .dropdown-content {
            position: absolute;
            visibility: hidden;
            color: var(--color-white);
            height: 0%;
            min-width: 100%;
            transition: height .3s;
        }

        &.open {
            
            .select-box {
                z-index: 3;
            }

            
            .dropdown-content {
                visibility: visible;
                top: 90%;
                left: 0;
                height: fit-content;
                min-width: 100%;
                display: flex;
                flex-direction: column;
                gap: 4px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                background-color: var(--color-black-400);
                padding: 24px 16px;
                z-index: 1;

                .select-item {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 0;
                }
            }
        }
    } 
}


