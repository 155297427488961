@import "../videoGrid/videoGridMyUploads.scss";

#my-upload-page {

    .info-empty-videos {
        color: var(--color-white);
        font-size: 19px;
        letter-spacing: 0.19px;
        text-align: center;
        padding: 0 0 24px;
        max-width: 255px;
        margin-inline: auto;
    }

    button {
        margin-inline: auto;
        
    }
}

@media screen and (min-width: 525px) {
    #my-upload-page {
    
        button {
            margin-bottom: 22px;
            
        }
    }
}

@media screen and (min-width: 1179px) {
    .info-empty-videos {
        color: var(--color-white);
        font-size: 19px;
        letter-spacing: 0.19px;
        text-align: center;
        padding: 0 0 24px;
        max-width: 100% !important;
        margin-inline: auto;
    }
}