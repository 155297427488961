#upload-main, #upload-main-v2 {


  .video-container {

    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding-bottom: 24px;
    justify-content: center;

    .empty {
      max-width: calc(100% / 2 - 6px);
      margin: 0 6px 0 0;
      border-radius: 8px;
      height: auto;
      border: dashed 1px var(--color-black-200);
      justify-content: center;
      align-content: center;

      .rs-icon {
        color: var(--color-black-200);
      }
    }

    .video {
      color: white;
      position: relative;
      max-width: calc(100% / 3 - 6px);
      border-radius: 8px;


      .svg.trash {
        position: absolute;
        top: 8px;
        right: 8px;

      }

      video {
        width: 100%;
        height: auto;
        border-radius: 8px;
        border: 2px solid transparent
      }
    }


    &.autocreate {

      video {
        height: calc(100% - 40px);
      }

      .select-audio-action {
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        padding-top: 6px;
      }

      .video.grid-item {
        margin: 0 6px 0 0;
        border-radius: 8px;
        border: solid 1px var(--colors-black-black-300);
      }


      .video.selected {
        video {
          background-color: var(--color-turquoise);
          border: 2px solid var(--color-turquoise);
        }

        .select-audio-action {
          color: var(--color-turquoise);

          .audio-on {
            transform: scale(.9472);
          }
        }
      }
    }
  }


}

@media screen and (min-width: 300px) {
  #upload-main, #upload-main-v2 {
    .video-container {
      margin-bottom: -30px;
      .video {
        max-width: calc(100% / 2 - 6px);
      }

      .empty {
        min-width: calc(100% / 2 - 9px);
        max-width: calc(100% / 2 - 9px);
        min-height: 80px;

        .rs-icon {
          color: var(--color-black-200);
        }
      }
    }
  }
}

@media screen and (min-width: 390px) {
  #upload-main, #upload-main-v2 {
    .video-container {
      margin-bottom: -30px;
      .video {
        max-width: calc(100% / 2 - 6px);
      }

      .empty {
        min-width: calc(100% / 2 - 20px);
        max-width: calc(100% / 2 - 20px);
        min-height: 110px;

        .rs-icon {
          color: var(--color-black-200);
        }
      }
    }
  }
}

@media screen and (min-width: 425px) {
  #upload-main, #upload-main-v2 {
    .video-container {
      .video {
        max-width: calc(100% / 2 - 6px);
      }

      .empty {
        min-width: calc(100% / 2 - 9px);
        max-width: calc(100% / 2 - 9px);
        min-height: 120px;

        .rs-icon {
          color: var(--color-black-200);
        }
      }
    }
  }
}

@media screen and (min-width: 767px) {
  #upload-main, #upload-main-v2 {
    .video-container {
      .video {
        max-width: calc(100% / 2 - 6px);
      }

      .empty {
        min-width: calc(100% / 2 - 13.5px);
        max-width: calc(100% / 2 - 13.5px);
      }
    }
  }
}

@media screen and (min-width: 1020px) {
  #upload-main, #upload-main-v2 {
    .video-container {
      .empty {
        min-width: calc(100% / 2 - 12px);
        max-width: calc(100% / 2 - 12px);
        min-height: 160px;
        margin: 0 6px 0 0;
        border-radius: 8px;
        border: dashed 1px var(--color-black-200);
        justify-content: center;
        align-content: center;

        .rs-icon {
          color: var(--color-black-200);
        }
      }
    }
  }
}



