
#tutorial-page {

    .tutorial-video {
        height: calc(100vh - 64px);
        background-color: var(--color-black-600);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin: 0 -20px;
        
        .tutorial-action-container {
            color: #BBBBBB;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 24px;
            width: 100%;

            svg {
                width: 20px !important;
                height: 20px  !important;
            }

            .actions {
                display: flex;
                align-items: center;

                .close-action,
                .full {
                    padding: 12px 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 100% !important;
                    transition: .3s linear;

                    &:active {
                        transform: scale(.9);
                    }
                }

                /* .close-action {
                    background-color: var(--btn-close-modal-video);
                    border-top-left-radius: 16px;
                    border-bottom-left-radius: 16px;
                } */

                .full {
                    background-color: var(--btn-full-video-modal);
                    border-radius: 16px;
                }
            }

            .sound {
                    background-color: var(--btn-audio-video-modal);
                    line-height: 100% !important;
                    border-radius: 16px;
                    padding: 12px 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .3s linear;
                    position: relative;

                    /* &.muted {
                        &::after {
                            content: " ";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                            height: 90%;
                            width: 1px;
                            background-color: #BBBBBB;

                        }
                    } */

                    &:active {
                        transform: scale(.9);
                    }

                    
            }
        }

        .video-container {
            height: calc(100% - 90px - 92px - 24px);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            video {
                height: 100%;
                width: 100%;
            }
        }

        .video-controls {
            
            width: 100%;
            padding: 0 24px;
            margin-bottom: 24px;

            .cta-controls {
                height: 90px;
                max-width: 420px;
                margin-inline: auto;
                background-color: var(--color-video-controls);
                border-radius: 24px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 6px;
                padding: 0 16px;

                .progress-bar-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    /* ************ */

                    .ant-slider {
                        margin: 0;

                        .ant-slider-track {
                            background: #7C7C7C;
                            height: 3px;
                        }
                        .ant-slider-rail {
                            background: #3E3E3E;
                            height: 3px;
                        }
                        .ant-slider-handle {
                            background: white;
                            width: 12px;
                            height: 12px;
                            border-radius: 100%;
                            &::after,
                            &::before {
                                visibility: hidden;
                            }
                        }
                        .ant-slider-handle-active {
                            background: #929293;
                            width: 12px;
                            height: 12px;
                            border: none;
                        }
                        .ant-slider-dot {
                            border: 1.5px solid #29292c;
                            border-radius: 1px;
                            background: #29292c;
                            width: 2px;
                            height: 10px;
                        }
                        .ant-slider-dot-active {
                            border: 1.5px solid #929293;
                            border-radius: 1px;
                            background: #929293;
                            width: 2px;
                            height: 10px;
                        }
                        .ant-slider-with-marks {
                            margin-bottom: 50px;
                        }
                    }

                    /* ************ */


                    .time {
                        color: #ACACAC;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                .cta-video {
                    color: #E3E3E3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 24px;
                    

                    .svg {
                        transition: .3s ease-in-out;
                        
                        &:active {
                            transform: scale(.8);
                        }
                    }
                }
            }
        }
    }
}
