/* === BTN === */

button a {
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

button {
  &.h-56 {
    height: 56px !important;
  }
}

button.default-big {
  background-color: var(--color-turquoise);
  color: var(--color-white);
  font-size: 1.188rem;
  letter-spacing: 0.19px;
  width: 100%;
  max-width: 425px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: 0.4s;
  border: none;

  &.default-color {
    background-color: var(--color-black-300);
  }

  &.h-48 {
    height: 48px !important;
    min-height: 48px !important;
  }

  &.fs-1 {
    font-size: 1rem;
  }

  &.m-inline {
    margin-inline: auto;
  }

  &.bottom {
    margin: auto 0 22px;
  }
  &.m-t-24 {
    margin-top: 24px;
  }

  &:active {
    opacity: 0.6;
  }

  &.not-active {
    opacity: 0.4;
  }

  &.text-small {
    font-size: 1rem;
  }
}

button {
  cursor: pointer;
  line-height: 100% !important;
}

button.default {
  background-color: var(--color-black-300);
  color: var(--color-white);
  font-size: 1rem;
  letter-spacing: 0.3px;
  min-width: fit-content;
  height: 48px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
  border: none;
  border-radius: 8px;
  transition: 0.4s;
  margin: 0 auto;

  &:active {
    opacity: 0.6;
  }

  &.not-active {
    opacity: 0.3;
  }

  &.w-96 {
    width: 96px;
  }
}

button.delete {
  background-color: var(--color-red);
  color: var(--color-white);
  font-size: 1rem;
  letter-spacing: 0.3px;
  width: 342px;
  height: 48;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: 0.4s;

  &:active {
    opacity: 0.7;
  }
}

button.logo-text {
  background-color: var(--color-white);
  color: var(--color-black-500);
  font-size: 1rem;
  width: 153px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
  border-radius: 8px;
  transition: 0.4s;

  &:active {
    opacity: 0.8;
  }
}

/* === false btn / input type file */
button.add-videos {
  position: relative;
  background-color: var(--color-black-300);
  color: var(--color-white);
  font-size: 1rem;
  letter-spacing: 0.3px;
  width: fit-content;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  transition: 0.4s;
  margin: 24px auto 12px;
  cursor: pointer;

  label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    z-index: 1;
  }
}

/* double-button */
.double-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 8px;

  button {
    max-width: 60%;
    margin-inline: auto;
    color: var(--color-white);
    font-size: 16px;
    letter-spacing: 0.3px;
    flex: 1;
    height: 48px;
    border-radius: 8px;
    border: none;
    outline: none;
    transition: 0.3s linear;

    &.download {
      background-color: var(--color-turquoise);
    }

    &.remix {
      background-color: var(--color-black-300);
    }

    &:active {
      opacity: 0.6;
      transform: scale(0.9);
    }
  }
}

/* === LINKS === */
.default-link {
  color: var(--color-white);
  font-size: 1rem;
  letter-spacing: 0.3px;

  &.underline {
    text-decoration: underline;
  }

  &:active {
    color: var(--color-black-300);
  }
}

/* === SVG === */

.svg {
  display: flex;
  align-items: center;
}

.svg.close {
  color: var(--color-white);
  svg {
    opacity: 1 !important;
    fill-opacity: 1 !important;
    color: var(--color-white) !important;
    fill: var(--color-white) !important;
  }
  &:active {
    color: var(--color-black-300) !important;
    fill: var(--color-black-300) !important;
  }
}

@media screen and (min-width: 1179px) {
  /* double-button */
  .double-button {
    button {
      max-width: 342px;
    }
  }
}
