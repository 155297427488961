#register-page {

    .otp-form {

        .input-control-otp {
            padding: 20px 0 0;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .input-container {
                align-self: center;
                display: flex;
                gap: 8px;
                transition: .3s;
            
                input[class*="otp"] {
                    color: var(--color-white);
                    background-color: transparent;
                    font-size: 28px;
                    height: 56px;
                    width: 35px;
                    text-align: center;
                    border-radius: 8px;
                    border: 1px solid var(--color-black-300);
                    outline: none;
                }

                &.error {
                    input {
                        border-color: var(--color-error-input);
                    }
                }
            }

            .error-message {
                font-size: 1rem;
                color: var(--color-error-input);
                letter-spacing: 0.2px;
                line-height: 100%;
            }
        }

        .request-new-code {
            cursor: pointer;
            color: var(--color-white);
            font-size: 16px;
            letter-spacing: 0.3px;
            margin-top: 16px;
        }

        button {
            margin-inline: auto;
        }
    }
 }

 @media screen and (min-width: 500px ) {
    #register-page {

        .otp-form {
            .input-control-otp {
    
                .input-container {
                    align-self: center;
                    display: flex;
                    gap: 16px;    
                }
            }
        }
    }
 }