#add-effect-page {
    .effect-video-container {
            width: 100%;
            height: 100%;
            max-width: 1020px;
            margin: 0 auto;
            padding-bottom: 24px;
            overflow: hidden;
    
            .video-container-scrollable {
                display: flex;
                gap: 6px;
                flex-wrap: wrap;
                height: -moz-fit-content;
                width: 100%;
                max-width: 678px;
                margin-inline: auto;
                height: fit-content;
                max-height: 100%;
                overflow: scroll;
                margin-top: 50px;
                padding-bottom: 30px;
    
            }
              
            .video {
                position: relative;
                color: var(--color-white);
                width: calc(100% / 3 - 6px);
                height: auto;
                border-radius: 8px;


                video {
                    width: 100%;
                    height: auto;
                    max-height: 160px;
                    border-radius: 8px;
                }

                .svg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid transparent;
                    border-radius: 8px;
                    margin-bottom: 7px;
                    
                    svg {
                        opacity: 1 !important;
                        width: 60% !important;
                        height: 50% !important;
                        
                    }
                }

                &.selected {

                    video {
                        border: 2px solid var(--color-white);
                    }

                    .svg {
                        border: 2px solid var(--color-white);
                    }
                }
            }
    }
}

@media screen and (min-width: 768px) {
    #add-effect-page {
        .effect-video-container {
            .video {
                min-width: calc(100% / 4 - 13.5px);
                max-width: calc(100% / 4 - 13.5px);
                
            }
        }
    }
}
    
@media screen and (min-width: 1020px) {
    #add-effect-page {
        .effect-video-container {
                gap: 24px 16px;
                .video-container-scrollable{
                max-width: 100%;
                    .video {
                        min-width: calc(100% / 4 - 7px);
                        max-width: calc(100% / 4 - 7px);
                    }
                }
        }
    }
}