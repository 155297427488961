#update {
    width: 100%;
    height: calc(100vh - 64px);
    margin-inline: auto;
    background-color: var(--color-blue);
    display: flex;
    flex-direction: column;
    padding: 24px 20px;

    .update-container {
        width: 100%;
        max-width: 1180px;
        height: 100%;
        margin-inline: auto;

        display: flex;
        flex-direction: column;
        gap: 12px;

        .btn-close {
            display: flex;
            justify-content: flex-end;
           padding: 0 0 ;
            
            .svg {
                
                svg {
                    border-radius: 100%;
                    border: 1px solid white;
                    padding: 4px;
                    width: 24px;
                    height: 24px;
                }
            }
        }



        .content {
            color: white;
            width: 100%;
            max-width: 500px;
            height: 100%;
            margin-inline: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 24px;

            .icon {
                width: 100%;
                display: flex;
                justify-content: center;
                .svg {
                    svg {
                        width: 72px;
                    }
                }
            }

            .text-primary {
                font-size: 28px;
                font-weight: bold;
                line-height: 1.21;
            }
    
    
            .text-secondary {
                font-size: 1rem;
                letter-spacing: .3px;
            }

            button {
                margin-top: 24px;
            }
        }
    }
}

@media screen and (min-width: 1179px) {

    #update {
    
        .update-container {
            gap: 24px;

            .btn-close {

                padding: 0 80px;
            }

            .content {
                button {
                    margin-top: 8px;
                }
            }
        }
    }
}