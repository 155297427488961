.title-page {
    color: var(--color-white);
    width: 100%;
    max-width: 500px;
    font-size:1.75rem ;
    line-height: 2.125rem;
    padding: 24px 16px;
    text-align: center;
    margin: 0 auto;

    &.uppercase {
        text-transform: uppercase;
    }
}

@media screen and (min-width: 1179px) {
    .title-page {
        padding-top: 70px !important;
    }
}