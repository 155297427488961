#my-videos-page {
    
    .my-video-container {
            width: 100%;
            height: 100%;
            max-width: 1020px;
            margin: 0 auto;
            padding-bottom: 24px;
            overflow: hidden;
    
    
            .video-container-scrollable {
                display: flex;
                gap: 24px 9px;
                flex-wrap: wrap;
                height: -moz-fit-content;
                width: 100%;
                height: fit-content;
                max-height: 100%;
                overflow: auto;
            }

            .video,
            .placeholder-container {
                position: relative;
                color: var(--color-white);
                min-width: calc(100% / 2 - 4.5px);
                max-width: calc(100% / 2 - 4.5px);
                height: fit-content;
                border-radius: 8px;
                overflow: hidden;
                padding-bottom: calc(24px - 9px);
                font-size: 1rem;
                letter-spacing: .3px;
    
                .video {
                    cursor: pointer;
                }
                
                video,
                .video {
                    width: 100%;
                    height: auto;
                    max-height: 160px;
                    border-radius: 8px;
                }

                .category {
                    padding: 9px 0 4px;
                    font-weight: bold;
                }
            }

            .placeholder-container {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 12px;
        
                .video {
                    min-width: 100% !important;
                    height: 90px;
                    border: 1px dashed var(--color-black-300);
                }
        
                .text {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
        
                    
                    span {
                        display: block;
                        width: 97%;
                        height: 8px;
                        background-color: var(--color-black-400);
                        border-radius: 8px;
                    }
        
                    
                     span:last-child{
                        width: 55%;
                    }
                }
            }

            .placeholder-container:nth-child(3),
            .placeholder-container:nth-child(4) {
                display: none;
            }
    
        }
    }
    
    @media screen and (min-width: 425px) {
        #my-videos-page {
            .my-video-container {
                max-width: 678px;

                .video-container-scrollable {

                    .video,
                    .placeholder-container {
                        min-width: calc(100% / 3 - 9px);
                        max-width: calc(100% / 3 - 9px);
                    }    

                    .placeholder-container:nth-child(3) {
                        display: flex;
                    }
                }
            }
        }
    }
    
    @media screen and (min-width: 767px) {
        #my-videos-page {
            .my-video-container {
                .video-container-scrollable {

                    .video,
                    .placeholder-container {
                        min-width: calc(100% / 4 - 13.5px);
                        max-width: calc(100% / 4 - 13.5px);
                    }

                    .placeholder-container:nth-child(4) {
                        display: flex;
                    }
                }
            }
        }
    }
    
    @media screen and (min-width: 1020px) {
        #my-videos-page {
            .my-video-container {
                max-width: 1000px;
                .video-container-scrollable {
                    gap: 24px 16px;
               
                    .video,
                    .placeholder-container {
                        min-width: calc(100% / 4 - 12px);
                        max-width: calc(100% / 4 - 12px);
                    }

                    .placeholder-container {
                        .video {
                            height: 133px;
                        }
                    }
                }
            }
        }
    }