#cookie_privacy-page {
    
    .privacy-text {
        color: var(--color-white);
        font-size: 19px;
        line-height: 26px;
        letter-spacing: 0.19px;
        height: 100%;
        max-height: calc(100vh - 82px - 64px);
        overflow: hidden;
    
        .scroll-container {
            height: 100%;
            padding: 0 0 24px;
            overflow: auto;
        }
        
        .inner-container {
            margin: 1rem 0 1rem 0;
            a, p, li {
                font-size: clamp(0.875rem, 0.359rem + 2.581vw, 1.125rem);
            }
        }
            
        h4 {
            font-size: clamp(1rem, -0.032rem + 5.161vw, 1.5rem);
            color: inherit;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        a.redirectTo {
            text-decoration: underline;
        }

        ol {
            list-style-type: decimal;
        }

        li {
            margin: 1rem 0.65rem 1rem 0.65rem;
            list-style-position: inside;
            text-align: left;
        }
    
        /* ===== Scrollbar CSS ===== */
        /* Firefox */
        * {
            scrollbar-width: auto;
            scrollbar-color: var(--color-primary) #ffffff;
        }
    
        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
    
        *::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 8px;
        }
    
        *::-webkit-scrollbar-thumb {
            background-color: var(--color-primary);
            border-radius: 10px;
            border: 2px solid #ffffff;
        }
    }
}

@media screen and (min-width: 575px) {
    #cookie_privacy-page {

        .privacy-text {

            .scroll-container {
                max-width: 675px;
                margin-inline: auto;
            }
        }
    }
}