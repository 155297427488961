
#support-page {
    button {
        margin-top: 24px;
        margin-bottom: 32px;
        font-size: 16px;
        letter-spacing: .3px;
    }
    .tutorial {
        color: var(--color-white);
        font-size: 1rem;
        letter-spacing: 0.3px;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }
}

@media screen and (min-width: 768px) {
    #support-page {
        .tutorial {
            justify-content: center;
        }
    }
    
}