.main-info {
    color: var(--color-white);
    width: 100%;
    max-width: 500px;
    font-size:19px ;
    letter-spacing:.19px;
    text-align: center;
    margin-top: -14px;
    margin-inline: auto;

    &.m-normal {
        margin-top: 0;
    }

    &.text-normal {
        text-align: start;
    }

}

@media screen and (min-width: 1179px) {

}