@import "../videoGrid/videoGridMyVideos.scss";

#my-videos-page {


    button {
        margin-inline: auto;
    }
}

@media screen and (min-width: 525px) {
    #my-videos-page {


        button {
            margin-bottom: 22px;
        }
    }
}