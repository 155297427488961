nav#navbar {
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    overflow: hidden;
    visibility: hidden;
    transition: .4s;

    .nav-header {
        background-color: var(--color-black-500);
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 20px 15px 16px;

        .svg {
            transition: .4s;
            &:active {
                transform: scale(.8);
            }
        }
    }

    .nav-item-container {
        background-color: var(--color-black-400);
        height: calc(100% - 64px);
        padding: 24px 16px 14px;
        display: flex;
        flex-direction: column;

        .first-row-item,
        .second-row-item,
        .copyright-row-item {
            display: flex;
            flex-direction: column;
            gap: 21px;

            .item {
                color: var(--color-white);
                font-size: 1.188rem;
                letter-spacing: 0.19px;
                height: 27px;
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 1px 4.9px 0 0;

                &.active {
                    color: var(--color-turquoise) !important;

   
                        svg {
                            fill: orange !important;
                            g {
                                path,
                                rect,
                                circle {
                                    
                                    stroke: var(--color-turquoise) !important;
                                }
                            }
                            

                    }
                }
                
                &:active {
                    color: var(--color-black-300);
                }
    
                &:nth-last-child {
                    color: var(--color-black-300);
                    margin-top: 32px;

                    &:active {
                        color: var(--color-white);
                    }
                }

               
            }
        }

        .second-row-item {
            margin-top: auto;
        }

        .copyright-row-item {
            margin-top: 32px;
            
            .item {
                color: var(--color-black-500);
                font-size: 1rem;
                &.reserved {
                    cursor: default !important ;
                }
            }
        }

        
    }

    &.show {
        top: 0;
        visibility: visible;
    }
}

@media screen and (min-width: 1179px) {
    nav#navbar {
        width: 375px;
        top: 0;
        left: 100%;
    
        .nav-header {
            background-color: var(--color-black-400);
            flex-direction: row-reverse;
            padding: 17px 80px 15px 16px;

            .svg {
                &.play-logo { display: none;}

                transition: .4s;
                &:active {
                    transform: scale(.8);
                }
            }
        }
    
        .nav-item-container {

            padding: 43px 16px 37px 33px;
    
        }
    
        &.show {
            left: calc(100% - 374px);
        }
    }
}

@media screen and (min-width: 1920px) {
    nav#navbar {
        width: 522px;
        left: 100%;
    
        .nav-header {
            flex-direction: row !important;
            padding: 36px 40px 16px;

            .svg {
                &.play-logo { display: none;}

                transition: .4s;
                &:active {
                    transform: scale(.8);
                }
            }
        }
    
        .nav-item-container {

            padding: 43px 16px 37px 33px;
    
            .second-row-item {
                margin-top: 140px;
            }
        }
    
        &.show {
            left: calc(100% - 521px);
        }
    }
}