#privacy-page {

    .privacy-text {
        color: var(--color-white);
        font-size: 19px;
        line-height: 1.75em;
        letter-spacing: 0.19px;
        height: 100%;
        max-height: calc(100vh - 82px - 64px);
        overflow: hidden;
    
        .scroll-container {
            height: 100%;
            padding: 0 0 24px;
            overflow: auto;
    
        }
    
        a {
            color: inherit;
            text-decoration: none;
        }
    
        /* ===== Scrollbar CSS ===== */
        /* Firefox */
        * {
            scrollbar-width: auto;
            scrollbar-color: var(--color-primary) #ffffff;
        }
    
        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
    
        *::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 8px;
        }
    
        *::-webkit-scrollbar-thumb {
            background-color: var(--color-primary);
            border-radius: 10px;
            border: 2px solid #ffffff;
        }
    }
}

@media screen and (min-width: 575px) {
    #privacy-page {

        .privacy-text {

            .scroll-container {
                max-width: 675px;
                margin-inline: auto;
            }
        }
    }
}