@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

:root {
/* === FONTS === */
    --font-primary-almarai: 'Almarai', sans-serif;

/* === COLORS === */
    --color-black-600: #000000 ;
    --color-black-500: #12161e ;
    --color-black-400: #2d3340 ;
    --color-black-300: #515464;
    --color-black-200: #6f7787 ;
    --color-white: #ffffff ;
    --color-blue: #0062cc ;
    --color-turquoise: #009976 ;
    --color-red: #b20027 ;
    --color-orange: #e25d00 ;
    --color-cyan: #16c2e1 ;
    --color-purple: #771187 ;
    --color-yellow: #e2bc00 ;
    --color-green: #52bf2a ;

    // modal video full screen
    --color-video-controls: #242424;
    --btn-close-modal-video: #151515;
    --btn-full-video-modal: #242424;
    --btn-audio-video-modal: #242424;

    /* error-color */
    --color-error-input: #ce022f;

    /* border-bottom-default */
    --border-bottom-default: #30333a;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: var(--font-primary-almarai)
}

body {
    background-color: var(--color-black-500);
    line-height: 19px;
}

main {
    padding: 0 20px;

    // support class
    &.h_full-nav {
        height: calc(100vh - 64px);
        display: flex;
        flex-direction: column;
    }

    &.btn_inline {
        button {
            margin-inline: auto !important;
        }
    }
    &.no_pad {
        padding: 0 !important;
    }
    &.h_full_hidden {
        width: 100%;
        height: calc(100vh - 64px);
        overflow:   hidden;
    }
}

/*=== IMPORT HEADER - NAV - UTILS - UI === */
@import "./header/header.scss";
@import "./nav/nav.scss";
@import "./utils/utils.scss";
@import "./UI/UI.scss";

/*=== IMPORT PAGES === */
@import "./pages/pages.scss";

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: var(--color-primary) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 10px;
    border: 2px solid #ffffff;
}