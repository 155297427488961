
header#header {
    background-color: var(--color-black-500);
    width: 100%;
    max-width: 1180px;
    height: 64px;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 20px 15px 16px;

    .svg {
        transition: .4s;
        &:active {
            transform: scale(.8);
        }
    }
}

@media screen and (min-width: 1179px) {
    header#header {
        padding: 16px 80px 15px 80px;
    }
}

@media screen and (min-width: 1920px) {
    header#header {
        
        margin: 0 auto;
        padding: 16px 80px 15px 80px;

        .svg {
            transition: .1s;

            &.invisible {
                visibility: hidden;
            }

        }

    }
}