#login-page {
    .never-been-here {
        margin: 0 auto ;
        width: 100%;
        max-width: 500px;
        color: var(--color-white);
        display: flex;
        flex-direction: column;
        gap: 18px;
        padding: 32px 0 0 ;

        
        h3 {
            font-size: 19px;
            font-weight: bold;
            letter-spacing: 0.19px;
            color: var(--color-white);
        }

        .tutorial {
            font-size: 1rem;
            letter-spacing: 0.3px;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
        }

        button {
            width: 100%;
            margin: 0 auto;
        }
    }
}

@media screen and (min-width: 767px) {
    .never-been-here {
        padding-left: 0 !important;
        padding-right: 0 !important;

        button {
            max-width: 178px;
            max-height: 48px;
            margin: 0px !important;
        }
    }
}