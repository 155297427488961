
.play-sel {
  background-color: var(--color-black-400) !important;
  border: 1px solid var(--color-black-400) !important;

  .rs-picker-select-menu-item {
    color: var(--color-white) !important;
  }
}

.loc-modal {

  .content-video {
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;

    video {
      border-radius: 8px;
      width: 100%;
      height: auto;
    }

    .svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s;

      svg {
        transform: scale(2.3333);
        transition: .3s;
      }

      &:active {
        svg {
          transform: scale(2);

        }
      }
    }
  }

  .no-video-placeholder {
    width: 96px;
    height: 96px;
    margin: 20px 0 40px;
    color: var(--color-black-300);

  }

  .rs-modal-content {
    min-height: 390px;
    background-color: var(--color-black-400) !important;
    border: 1px solid var(--color-black-300) !important;
    color: var(--color-white);
    font: var(--font-primary-almarai);
    font-size: 16px;
    //   padding:40px;
  }

  .rs-modal-title {
    color: var(--color-white);
    font: var(--font-primary-almarai);
    font-size: 18px;
    font-weight: bold;
  }

  .rs-modal-body {
    line-height: 1.5em;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .rs-btn-primary {
    background-color: var(--color-turquoise) !important;
  }

  .rs-btn-subtle {
    background-color: var(--color-black-300) !important;
  }

  .ul-loc {
    padding: 12px;
    line-height: 1.5em;
  }

  .rs-btn {
    min-width: 100px;
    padding: 13px 25.8px 15px 24.9px;
    color: var(--color-white);
    font: var(--font-primary-almarai);
    font-size: 16px;

    &:hover {
      opacity: 0.8;
      color: var(--color-white);
    }

  }
}

.loader {
  margin-bottom: 40px;
}

.rs-picker-disabled {
  opacity: 0.7 !important;
}

.rs-picker-select-menu-item {
  background-color: var(--color-black-400) !important;
  color: var(--color-white);
}

.rs-picker-select-menu-item-active {
  background-color: var(--color-black-300) !important;
  color: var(--color-white);
}

.rs-picker-select-menu-items {
  background-color: var(--color-black-400) !important;
  color: var(--color-white);
}

.rs-modal-lg {
  width: 1020px !important;
  height: 100%;
}

#lake-of-charity {

  padding: 0;

  .rs-modal-content {
    font-family: var(--font-primary-almarai);
    background-color: var(--color-black-500) !important;
    margin-top: 150px;
    width: 1020px !important;
    align-self: center;
  }

  .play-modal {
    width: 1020px !important;


  }

  .rs-header,
  .rs-content,
  .rs-footer,
  .rs-sidebar {
    color: var(--color-white);
    text-align: center;
  }

  .rs-btn {
    padding: 13px 25.8px 15px 24.9px;
    border-radius: 8px;
    font-family: var(--font-primary-almarai);
    font-size: 16px;
    color: var(--color-white);
    height: 48px;

    &:hover {
      opacity: 0.7;
    }
  }

  .close-button {
    width: 45px;
    height: 45px;
    margin-bottom: 17px;
    padding: 13px 13px 12px 12.9px;
    border-radius: 30px;
    border: solid 1.5px var(--color-white) !important;
    background-color: var(--color-black-500);
  }

  .primary {
    margin-top: 24px;
    background-color: var(--color-turquoise);
    margin-left: 11px;
    width: 175px;
  }


  .primary-dl {
    margin-top: 24px;
    background-color: var(--color-turquoise);
    width: 350px;
  }

  .secondary-dl {
    margin-top: 24px;
    width: 350px;
    background-color: var(--color-black-200);
  }


  .secondary {
    margin-top: 24px;
    width: 156px;
    background-color: var(--color-black-200);
  }

  .donate {
    background-color: var(--color-yellow);
    color: var(--color-black-400);

    .rs-btn-end-icon {
      font-size: 2.5em;
    }
  }

  .content-video {
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;

    video {
      border-radius: 8px;
      width: 100%;
      height: auto;
    }

    .svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s;

      svg {
        transform: scale(2.3333);
        transition: .3s;
      }

      &:active {
        svg {
          transform: scale(2);

        }
      }
    }
  }
  .divider {
    font-weight: bold;
  }
  .video {
    width: 166px;
    height: 166px;
    border-radius: 8px;
    opacity: 0.9;

    &:hover {
      opacity: 1.5;
    }

  }

  .play-logo > svg {
    margin-top: 24px;
    height: 42px;
    width: 100px;
  }

  .rs-navbar {
    margin-top: 24px;
    background-color: var(--color-black-500);
  }

  .rs-navbar-brand {
    font-family: var(--font-primary-almarai);
    font-size: 14px;
    color: var(--color-black-200);
    margin: 0;
    padding: 16px 8px 8px 16px;

    a:hover {
      opacity: 0.7;
    }
  }

  .rs-header {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .video-container {
    width: 100%;
    height: 100%;
    max-width: 1020px;
    margin: 0 auto;
    padding-bottom: 24px;
    overflow: hidden;
  }

  .loc-info-title {
    width: 300px;
    height: 50px;
    margin-bottom: 17px;
    font-family: var(--font-primary-almarai);
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.19px;
    color: var(--white);
    text-transform: uppercase;
  }

  .rs-picker-menu {
    border: solid 1px var(--colors-black-black-300) !important;
    background-color: var(--color-black-400) !important;
    color: var(--color-white) !important;
  }

  .play-select {
    height: 56px;
    border-radius: 8px;
    font-family: var(--font-primary-almarai);
    font-size: 16px;

  }

  .rs-picker-toggle-value {
    color: var(--color-white);
  }

  .rs-picker-toggle {
    background-color: var(--color-black-500) !important;
    border-color: var(--color-black-400);
  }

  .rs-picker-toggle-active {
    background-color: var(--color-black-400) !important;
  }

  .rs-picker-toggle-caret, .rs-picker-toggle-clean {
    color: var(--color-white);
    margin-top: 0.5em;
    margin-right: 1em;

  }


  .rs-picker-select-menu {
    border: solid 1px var(--colors-black-black-300) !important;
    background-color: var(--color-black-400) !important;
    color: var(--color-white);
  }

  .no-video-placeholder {
    width: 128px;
    height: 128px;
    margin: 20px 0 40px;
    color: var(--color-black-400);
  }

  .spaced {
    margin-top: 24px;
  }

  .panel-loc-donate {
    height: 290px;
    max-width: 510px;
    width: 510px;
    // margin: 40px 0 21px;
    padding: 24px 16px 47px;
    background-color: var(--color-black-400);
    border-radius: 0;

    .rs-panel-body {
      padding: 0;
    }
  }

  .panel-loc-donate-full {
    background-color: var(--color-black-400);
  }

  .panel-loc-play {
    height: 290px;
    max-width: 510px;
    //  margin: 40px 0 21px;
    padding: 25px 16px 32px 16px;
    border-radius: 0;
    background-color: var(--color-black-600);

    .rs-panel-body {
      padding: 0;
    }
  }

  .no-pad {
    padding: 0 !important;

    .rs-panel-body {
      padding: 0;
      margin-bottom: 20px;
    }
  }

  .Copyright-2023-play {
    opacity: 0.2;
    font-family: var(--font-primary-almarai);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: var(--color-white);
  }

  .loc-header-image {
    width: 128px;
  }

  .placeholder {
    margin: 30px 0 20px 0;
  }

  .donate-for {
    width: 355px;
    height: 30px;
    margin: 18px 0 14px;
    font-family: var(--font-primary-almarai);
    font-size: 20px;
  }

  .all-videos-automatic {
    font-family: var(--font-primary-almarai);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.3px;
    text-align: center;
    color: var(--white);
  }
}

@media screen and (min-width: 300px) {

  .divider {
    min-width: 300px;
    width: 100%;
  }
  .panel-loc-video {
    margin-top: 24px;

    .rs-panel-body {
      padding: 0;
    }

    margin-left: 5px;
  }
  .stack-pad {
    padding: 0 20px;
  }
  .content-video {
    padding: 0 20px;
  }
  .loc-info-title {
    width: 300px;
    align-self: center;
  }
  .panel-loc-donate {
    border-radius: 0;
  }
  .panel-loc-play {
    border-radius: 0;
  }
  .panel-loc-donate-full {
    width: 100%;
    border-radius: 0;
  }
  .play-logo {
    margin-top: -16px;
  }
  .play-select {
    width: 343px;
  }
}

@media screen and (min-width: 668px) {

  .divider {
    min-width: 668px;
    width: 100%;
  }
  .play-select {
    width: 253px !important;
  }
  .stack-pad {
    padding: 0;
  }
  .content-video {
    padding: 0px;
  }
  .play-logo {
    margin-top: 0;
  }
  .loc-info-title {
    text-align: center;
    width: 450px !important;
    height: 50px;
    margin: 38px 45px 32px 0;
  }

}

@media screen and (min-width: 768px) {

  .divider {
    min-width: 768px;
    width: 100%;
  }
  .play-select {
    width: 203px !important;
  }

  .video {
    width: 242px !important;
    height: 242px !important;
  }
  .loc-modal {

    .content-video {
      position: relative;
      border-radius: 8px;
      display: flex;
      justify-content: center;

      video {
        width: 55%;
      }
    }
  }
  .play-logo {
    margin-top: 0;
  }
  .loc-info-title {
    text-align: left;
    width: 250px !important;
    height: 50px;
    margin: 38px 45px 32px 0;
  }

  .panel-loc-video {
    .rs-panel-body {
      padding: 0;
    }
  }
}

@media screen and (min-width: 1020px) {

  .divider {
    min-width: 1020px;
    width: 1020px;
  }
  .panel-loc-video {
    .rs-panel-body {
      padding: 0;
    }

    margin-left: 0px;
  }
  .panel-loc-donate {
    min-width: 510px;
    max-width: 510px;
    width: 510px;
    height: 279px;
    // margin: 40px 0 21px;
    padding: 29px 83px 76px 84px;
    border-radius: 8px 0 0 8px !important;
  }
  .panel-loc-play {
    min-width: 510px;
    height: 279px;
    max-width: 510px;
    border-radius: 0 8px 8px 0 !important;
  }

  .play-select {
    width: 329px !important;
  }
  .select-bar {
    flex-wrap: nowrap !important;
    padding: 0 20px;
  }
  .loc-info-title {
    text-align: left;
    width: 303px !important;
    height: 50px;
    margin: 17px 45px 32px 0;
  }
  .panel-loc-donate-full {
    width: 1020px;
  }
  .no-pad {
    padding: 0 !important;

    .rs-panel-body {
      padding: 0;
      margin-bottom: 20px;
    }
  }
}