#login-page {

    
    .login-form {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 0 32px;
        margin: 0 auto;
        border-bottom:1px solid var(--border-bottom-default);
    
        .input-control {
            display: flex;
            flex-direction: column;
            gap: 8px;

            
             input {
                background-color: transparent;
                color: var(--color-white);
                font-size: 1rem;
                letter-spacing: 0.3px;
                padding: 17px 16px;
                border-radius: 8px;
                border: 1px solid var(--color-black-300);
                outline: none;
    
                &::placeholder {
                    color: var(--color-black-300);
                }
            }

            .error-message {
                font-size: 1rem;
                color: var(--color-error-input);
                align-self: flex-end;
                text-align: end;
                letter-spacing: 0.2px;
                line-height: 100%;
            }

            &.error {

                input {
                    color: var(--color-error-input);
                    border-color: var(--color-error-input);
                }
            }
        }

        .option-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            
            .forgot-password {
                color: var(--color-white);
                font-size: 16px;
                letter-spacing: 0.3px;
                align-self: flex-start;
            }
        }
    }
}

@media screen and (min-width: 767px) {
    #login-page {
        .login-form {

        
    
            .option-container {

                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;

                button {
                    max-width: 144px;
                }

                .forgot-password {
                    align-self: auto;
                }
            }
        }
    }
}