#register-page {
    padding: 0 16px;

    .register-form {
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 0 32px;
        margin: 0 auto;


        .input-control {
            display: flex;
            flex-direction: column;
            gap: 8px;

            
             input {
                background-color: transparent;
                color: var(--color-white);
                font-size: 1rem;
                letter-spacing: 0.3px;
                padding: 17px 16px;
                border-radius: 8px;
                border: 1px solid var(--color-black-300);
                outline: none;
    
                &::placeholder {
                    color: var(--color-black-300);
                }

            }

            .error-message {
                font-size: 1rem;
                color: var(--color-error-input);
                align-self: flex-end;
                text-align: end;
                letter-spacing: 0.2px;
                line-height: 100%;
            }
            
            &.error {

                input {
                    color: var(--color-error-input);
                    border-color: var(--color-error-input);
                }
            }

        }

        .checkbox-control {
            display: flex;
            flex-direction: column;

            .check-container {
                display: flex;
                gap: 1rem;
                color: var(--color-white);
                font-size: 16px;
                line-height: normal;
                letter-spacing: 0.3px;
                align-items: center;


                .check {
                    min-width: 40px;
                    min-height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--color-black-300);
                    border-radius: 8px;
                }

            }
        }

        .error-message {
            margin-top: 5px;
            font-size: 1rem;
            color: var(--color-error-input);
            align-self: flex-end;
            text-align: end;
            letter-spacing: 0.2px;
            line-height: 100%;
        }

        .privacy-policy {
            color: var(--color-white);
            letter-spacing: 0.3px;

            
                a {
                    color: var(--color-white);
                }
        }

        .option-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            margin-top: 8px;
            
            .go-to-login {
                color: var(--color-white);
                font-size: 16px;
                letter-spacing: 0.3px;
                align-self: flex-start;
            }
        }
    }
}

