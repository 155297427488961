@import "../registerForm/registerform.scss";
@import "../registerForm/otpForm.scss";

#register-page {
    max-width: 500px;
    margin-inline: auto;

    button.default-big {
     max-width: 500px !important;
    }
    .input-control {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 20px 0 0;

        
         input {
            background-color: transparent;
            color: var(--color-white);
            font-size: 1rem;
            letter-spacing: 0.3px;
            padding: 17px 16px;
            border-radius: 8px;
            border: 1px solid var(--color-black-300);
            outline: none;
    
            &.error {
                color: var(--color-error-input)
            }

            &::placeholder {
                color: var(--color-black-300);
            }
        }

        .error-message {
            margin-top: 5px;
            font-size: 1rem;
            color: var(--color-error-input);
            align-self: flex-end;
            text-align: end;
            letter-spacing: 0.2px;
            line-height: 100%;
        }
    }
}